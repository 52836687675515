<!--  -->
<template>
  <div class="adminCont">
    <div class="leftCont">
      <ul class="leftCont_menu">
        <li
          v-for="(item, index) in menuArr.list"
          :class="{ active: preId == index }"
          :key="index"
          @click="changeAdmin(index)"
        >
          {{ item }}
        </li>
      </ul>
    </div>

    <div class="rightCont">
      <div class="rightCont_top">
        后台管理 > <span>{{ menuArr.list[preId] }}</span>
      </div>

      <div class="rightCont_list">
        <div class="rightCont_list_cont">
          <div class="userCont" v-if="preId == 0"></div>

          <div class="spaceCont" v-if="preId == 1">
            <el-table :data="tableData.list" height="100%" style="width: 100%">
              <el-table-column prop="name" label="空间名称" width="160">
                <template #default="scope">
                  <div class="spaceCont_name" :title="scope.row.name">
                    {{ scope.row.name }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column prop="user" label="编辑者" width="130" />
              <el-table-column prop="submitTime" label="提交时间" width="210" />
              <el-table-column prop="address" label="传送地图" width="120">
                <template #default="scope">
                  <el-button link type="info" size="small"> 查看 </el-button>
                </template>
              </el-table-column>

              <el-table-column prop="verify" label="查看展厅" width="120">
                <template #default="scope">
                  <el-button
                    link
                    type="info"
                    size="small"
                    @click="goSpace(scope.row)"
                  >
                    查看
                  </el-button>
                </template>
              </el-table-column>

              <!-- <el-table-column prop="verifyStatus" label="审核状态" width="160">

                                <template #default="scope">
                                    <el-select v-if="scope.row.verifyStatus=='' " v-model="scope.row.verifyStatus"
                                        @change='change(scope.row.verifyStatus,scope.row.uuid)' placeholder="待审核">
                                        <el-option v-for="item in options.list" :key="item.value" :label="item.label"
                                            :value="item.value" />
                                    </el-select>

                                    <div class="passed" v-if="scope.row.verifyStatus=='已通过'">已通过</div>
                                    <div class="fail" v-if="scope.row.verifyStatus=='未通过'">未通过</div>


                                </template>

                            </el-table-column>

                            <el-table-column prop="approvalTime" label="审核时间" width="180" /> -->
              <el-table-column prop="pubCancel" label="发布/取消" width="130">
                <template #default="scope">
                  <el-button
                    v-if="scope.row.status == 1"
                    link
                    type="info"
                    size="small"
                    @click="spacePublishBtn(scope.row.uuid)"
                  >
                    发布
                  </el-button>

                  <el-button
                    v-if="scope.row.status == 2"
                    @click="spaceRevertBtn(scope.row.uuid)"
                    link
                    type="info"
                    size="small"
                  >
                    取消
                  </el-button>
                </template>
              </el-table-column>

              <el-table-column
                prop="publishStatus"
                label="发布状态"
                width="130"
              />
              <el-table-column
                prop="approvalTime"
                label="发布时间"
                width="210"
              />
              <el-table-column prop="version" label="版本号" width="100">
                <template #default="scope">
                  <span style="margin-left: 10px"
                    >V{{ scope.row.version }}</span
                  >
                </template>
              </el-table-column>

              <el-table-column prop="access" label="访问权限" width="120" />
              <el-table-column
                prop="permissions"
                label="功能权限"
                width="120"
              />
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//             int = 0 草稿
// stSubmitted int = 1 已提交     ：待审核
// stApproved  int = 2 已审批    ：已通过
// stPublished int = 3 已发布     ：已通过 已发布
// stDroped    int = 4 审批不通过    ：未通过
import { ref, getCurrentInstance, reactive } from 'vue';
import { ElButton, ElIcon, ElMessage } from 'element-plus';
import {
  getAssetList,
  getTemplateList,
  getSpaceList,
  spaceApproval,
  spacePublish,
  spaceRevert,
  getSpaceInfo,
} from '@/api/index';
import mode from '../../api/modeType';
import { formDate } from '@/tools/tools';

export default {
  name: '',
  data() {
    return {};
  },

  components: {},
  //   emits:['closeEditImg'],
  setup(props, ctx) {
    const { proxy } = getCurrentInstance();
    const menuArr = reactive({
      list: ['用户管理', '展厅管理', '场景管理', 'BI管理'],
    });
    const value = ref('');
    const options = reactive({
      list: [
        {
          value: '未通过',
          label: '未通过',
        },
        {
          value: '已通过',
          label: '已通过',
        },
      ],
    });

    const tableData = reactive({
      list: [
        {
          name: 'ddd',
          updatetime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          address: '查看',
          verify: '进入审核',
          verifyStatus: '未通过',
          verifyTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          pubCancel: '取消',
          publishStatus: '已发布',
          createTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          version: 'v1',
          access: '所有人',
          permissions: '设置',
        },
        {
          name: 'ddd',
          updatetime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          address: '查看',
          verify: '进入审核',
          verifyStatus: '未通过',
          verifyTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          pubCancel: '取消',
          publishStatus: '已发布',
          createTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          version: 'v1',
          access: '所有人',
          permissions: '设置',
        },
        {
          name: 'ddd',
          updatetime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          address: '查看',
          verify: '进入审核',
          verifyStatus: '未通过',
          verifyTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          pubCancel: '取消',
          publishStatus: '已发布',
          createTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          version: 'v1',
          access: '所有人',
          permissions: '设置',
        },
        {
          name: 'ddd',
          updatetime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          address: '查看',
          verify: '进入审核',
          verifyStatus: '未通过',
          verifyTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          pubCancel: '取消',
          publishStatus: '已发布',
          createTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          version: 'v1',
          access: '所有人',
          permissions: '设置',
        },
        {
          name: 'ddd',
          updatetime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          address: '查看',
          verify: '进入审核',
          verifyStatus: '未通过',
          verifyTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          pubCancel: '取消',
          publishStatus: '已发布',
          createTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          version: 'v1',
          access: '所有人',
          permissions: '设置',
        },
        {
          name: 'ddd',
          updatetime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          address: '查看',
          verify: '进入审核',
          verifyStatus: '未通过',
          verifyTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          pubCancel: '取消',
          publishStatus: '已发布',
          createTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          version: 'v1',
          access: '所有人',
          permissions: '设置',
        },
        {
          name: 'ddd',
          updatetime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          address: '查看',
          verify: '进入审核',
          verifyStatus: '未通过',
          verifyTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          pubCancel: '取消',
          publishStatus: '已发布',
          createTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          version: 'v1',
          access: '所有人',
          permissions: '设置',
        },
        {
          name: 'ddd',
          updatetime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          address: '查看',
          verify: '进入审核',
          verifyStatus: '未通过',
          verifyTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          pubCancel: '取消',
          publishStatus: '已发布',
          createTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          version: 'v1',
          access: '所有人',
          permissions: '设置',
        },
        {
          name: 'ddd',
          updatetime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          address: '查看',
          verify: '进入审核',
          verifyStatus: '未通过',
          verifyTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          pubCancel: '取消',
          publishStatus: '已发布',
          createTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          version: 'v1',
          access: '所有人',
          permissions: '设置',
        },
        {
          name: 'ddd',
          updatetime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          address: '查看',
          verify: '进入审核',
          verifyStatus: '未通过',
          verifyTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          pubCancel: '取消',
          publishStatus: '已发布',
          createTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          version: 'v1',
          access: '所有人',
          permissions: '设置',
        },
        {
          name: 'ddd',
          updatetime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          address: '查看',
          verify: '进入审核',
          verifyStatus: '未通过',
          verifyTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          pubCancel: '取消',
          publishStatus: '已发布',
          createTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          version: 'v1',
          access: '所有人',
          permissions: '设置',
        },
        {
          name: 'ddd',
          updatetime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          address: '查看',
          verify: '进入审核',
          verifyStatus: '未通过',
          verifyTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          pubCancel: '取消',
          publishStatus: '已发布',
          createTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          version: 'v1',
          access: '所有人',
          permissions: '设置',
        },
        {
          name: 'ddd',
          updatetime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          address: '查看',
          verify: '进入审核',
          verifyStatus: '未通过',
          verifyTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          pubCancel: '取消',
          publishStatus: '已发布',
          createTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          version: 'v1',
          access: '所有人',
          permissions: '设置',
        },
        {
          name: 'ddd',
          updatetime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          address: '查看',
          verify: '进入审核',
          verifyStatus: '未通过',
          verifyTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          pubCancel: '取消',
          publishStatus: '已发布',
          createTime: formDate(
            new Date('2022-07-05T10:00:30.286Z'),
            'yyyy-MM-DD HH:mm:ss',
          ),
          version: 'v1',
          access: '所有人',
          permissions: '设置',
        },
      ],
    });

    const preId = ref('0');

    function change(_str, _id) {
      console.log(_str, '_str_str');
      console.log(_id, '_id_id');
      let statusNum = -1;
      if (_str == '未通过') statusNum = 4;
      if (_str == '已通过') statusNum = 2;

      mode.uuid = _id;

      spaceApproval({ status: statusNum })
        .then((data) => {
          console.log(data, 'spaceApproval');
          getSpaceListF();
          ElMessage({
            message: '设置成功!',
            type: 'success',
          });
        })
        .catch((err) => {});
    }
    function goSpace(_info) {
      mode.uuid = _info.uuid;
      console.log(_info, '_info11');
      mode.info = _info;
      mode.seeMode = 0;
      getSpaceInfo()
        .then((data) => {
          // if(data.code==200){
          console.log(data, 'getSpaceInfo');
          mode.pack = data.template;
          proxy.$router.replace({ path: 'spaceView' });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function spacePublishBtn(_id) {
      //    spacePublish({uuid:_id,message:'why'})
      //             .then((data) => {
      //                 console.log(data,'spacePublish')
      //                 getSpaceListF()
      //                 ElMessage({
      //                 message: '发布成功!',
      //                 type: 'success',
      //               })
      //             })
      //             .catch((err) => {
      //             })
      mode.uuid = _id;
      setSpaceStatus(2);
    }

    function spaceRevertBtn(_id) {
      mode.uuid = _id;
      setSpaceStatus(0);
      //    spaceRevert({uuid:_id})
      //             .then((data) => {
      //                 console.log(data,'spaceRevert')
      //                 getSpaceListF()
      //                 ElMessage({
      //                 message: '取消发布成功!',
      //                 type: 'success',
      //               })
      //             })
      //             .catch((err) => {
      //             })
    }

    function setSpaceStatus(statusNum) {
      console.log(mode.uuid, 'mode.uuid111');
      const str = statusNum == 2 ? '发布成功!' : '取消成功!';
      spaceApproval({ status: statusNum })
        .then((data) => {
          console.log(data, 'spaceApproval');
          getSpaceListF();
          ElMessage({
            message: str,
            type: 'success',
          });
        })
        .catch((err) => {});
    }

    function changeAdmin(_id) {
      console.log(_id, 'changeAdmin');
      preId.value = _id;

      mode.adminMenuId = _id;

      if (_id == 1) {
        const arr = ['未提交', '已提交', '已审批', '已发布', '审批不通过'];
        getSpaceListF();
      }
    }

    function getSpaceListF() {
      getSpaceList({ page: 0, pagesize: 100, type: 1 })
        .then((data) => {
          console.log(data, 'getSpaceList');
          data.results.forEach((element) => {
            element.submitTime = formDate(
              new Date(element.submitTime),
              'yyyy-MM-DD HH:mm:ss',
            );
            element.approvalTime =
              element.status == 2
                ? formDate(
                    new Date(element.approvalTime),
                    'yyyy-MM-DD HH:mm:ss',
                  )
                : '';
            element.publishTime = formDate(
              new Date(element.publishTime),
              'yyyy-MM-DD HH:mm:ss',
            );

            // element.publishStatus=arr[element.status]
            element.publishStatus =
              element.status == 2
                ? '已发布'
                : element.status == 0
                ? '未提交'
                : '未发布';
            element.verify = '进入审核';

            if (element.status == 0 || element.status == 1) {
              element.verifyStatus = '';
              // element.verify='进入审核'
            }
            if (element.status == 2 || element.status == 3) {
              element.verifyStatus = '已通过';
              // element.verify='审核完成'
            }
            if (element.status == 4) {
              element.verifyStatus = '未通过';
              // element.verify='审核完成'
            }
            element.access = '';
            element.permissions = '';
          });
          tableData.list = data.results;
          //             int = 0 草稿
          // stSubmitted int = 1 已提交     ：待审核
          // stApproved  int = 2 已审批    ：已通过
          // stPublished int = 3 已发布     ：已通过 已发布
          // stDroped    int = 4 审批不通过    ：未通过
        })
        .catch((err) => {});
    }

    return {
      menuArr,
      changeAdmin,
      preId,
      tableData,
      options,
      value,
      change,
      spacePublishBtn,
      spaceRevertBtn,
      goSpace,
    };
  },
  props: {},

  computed: {},

  watch: {},

  methods: {},

  created() {}, // 生命周期 - 创建完成（可以访问当前this实例）
  mounted() {
    // document.documentElement.scrollTop = document.body.scrollTop = 0
    document.getElementsByClassName('homeMiddle')[0].scrollTop = 0;
    this.changeAdmin(mode.adminMenuId);
  }, // 生命周期 - 挂载完成（可以访问DOM元素
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss">
.el-scrollbar__view {
  width: 100%;
}
.el-table tr {
  background-color: '#FBF9FF' !important;
  height: 53px;
}

.adminCont {
  .el-select {
    width: 104px;
  }
  .el-table th.el-table__cell > .cell {
    font-size: 14px;
    color: #666666;
    font-weight: bold;
  }

  .el-table .cell {
    font-size: 13px;
    color: #555555;
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/css/mixin';
.adminCont {
  position: relative;

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  overflow: hidden;

  .leftCont {
    background: white;
    height: 100%;
    width: 200px;
    // flex: 1;
    box-shadow: 0px 10px 20px 1px rgba(140, 139, 143, 0.08);
    box-sizing: border-box;
    &_menu {
      font-size: 14px;
      color: black;
      margin-top: 10px;
      text-align: left;

      .active {
        background: linear-gradient(112deg, #b224ef 0%, #7579ff 100%);
        color: white;
      }

      li {
        padding-left: 30px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        &:hover {
          background: linear-gradient(112deg, #b224ef 0%, #7579ff 100%);
          color: white;
        }
      }
    }
  }

  .rightCont {
    background: #f2f2f2;
    height: 100%;
    width: 400px;
    flex-grow: 1;

    &_top {
      width: 100%;
      height: 64px;
      background: white;
      font-size: 14px;
      color: rgb(110, 110, 110);
      text-align: left;
      line-height: 64px;
      padding-left: 33px;
      span {
        color: black;
      }
    }
    &_list {
      width: 100%;
      height: 100%;
      padding: 30px;
      box-sizing: border-box;
      &_cont {
        background: white;
        width: 100%;
        height: 83%;
        box-sizing: border-box;
        overflow-x: scroll;
        padding: 30px;

        .spaceCont {
          height: 100%;
          &_name {
            @include no-wrap;
            @include clamp(1);
          }
          .passed {
            border: 1px solid #52c41a;
            color: #52c41a;
            padding: 0px -3px;
            width: 104px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            box-sizing: border-box;
            border-radius: 3px;
            background: #53c41a11;
          }
          .fail {
            border: 1px solid #ff4d4f;
            color: #ff4d4f;
            padding: 0px -3px;
            width: 104px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            box-sizing: border-box;
            border-radius: 3px;
            background: #ff4d500e;
          }
        }
      }
    }
  }
}
</style>
