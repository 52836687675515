export default {
  token: '62a98ca0baefc7f475582310',
  topBoo: false,
  // user: 'a862f9c',
  assetUrl: 'https://zetaverse.obs.cn-east-3.myhuaweicloud.com:443/', // 资产库资源的baseurl
  addAssetArr: [], // 资产库的数组
  addAssetMusic: '',
  maxSelectNum: 100,
  assetID: '', // 资产的id
  // instanceID: '',//资产的id
  user: 'a862f9c', // 用户的信息a862f9c  b67e291
  uuid: '', // 场景的id
  homeMenuNum: 1, // 首页第几个按钮
  pack: '', // 云渲染模版的名字
  info: {},
  seeMode: 1, // 0是游客 1是编辑,
  adminMenuId: 0,
  z3d: '',
  AgroaInfo: {},
};
