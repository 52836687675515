import axios from 'axios';
// import Vue from 'vue'
import mode from './modeType';

// axios.defaults.baseURL = ''
// axios.defaults.baseURL = 'https://dev.zixel.cn/'

axios.defaults.baseURL = 'https://test.zixel.cn/zetaverse/api/';
// axios.defaults.baseURL = 'https://www.kabep.cn/'

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axios.defaults.timeout = 5000;

// 添加请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么

    //   Vue.showLoading()
    // console.log(config.url,'config.url')
    // console.log(mode.token,'token')

    if (mode.token) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      // config.headers['X-Token'] = mode.token; // 根据实际情况自行修改
      // config.headers['Content-Length'] = '3333';
      // config.headers['Content-Type'] = 'application/octet-stream';
      config.headers['User-id'] = mode.user;
      config.headers['User-Token'] = '62a98ca0baefc7f475582310333';
    }
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    //   Vue.hiddenLoading()
    return Promise.reject(error);
  },
);

// 添加响应拦截器
axios.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    // console.log(typeof(response))
    const { data } = response;

    if (data.code != 200) {
      // Vue.prototype.$message.error(data.message);
    }

    return response;
  },
  (error) => {
    // 对响应错误做点什么

    // this.$message.error(error.message);
    return Promise.reject(error);
  },
);

export default {
  get(path = '', data = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(path, {
          params: data,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  post(path = '', data = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(path, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  put(path = '', data = {}) {
    return new Promise((resolve, reject) => {
      axios
        .put(path, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  del(path = '', data = {}) {
    return new Promise((resolve, reject) => {
      axios
        .delete(path, { data })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  get1(path = '', assetId = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(path, null, {
          params: { assetId },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  all(list) {
    return new Promise((resolve, reject) => {
      axios
        .all(list)
        .then(
          axios.spread((...result) => {
            // 两个请求现在都执行完成
            resolve(result);
          }),
        )
        .catch((err) => {
          reject(err);
        });
    });
  },
};
