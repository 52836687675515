<!--  -->
<template>
  <div class="assetCont">
    <el-icon class="assetCont_close" @click="closeAssetPop" size="16" color="#000000">
      <CloseBold />
    </el-icon>

    <div class="assetCont_top">
      <div class="assetCont_top_menu">
        <div class="assetCont_top_menu_icon active" ref="btn1" @click="menuBtn(1)">
          <span>资产</span>
        </div>
        <div class="assetCont_top_menu_icon" ref="btn2" @click="menuBtn(2)">
          <span>素材</span>
        </div>
      </div>

      <div class="assetCont_top_rightCont">
        <div v-if="!isEdit" class="cont">
          <el-upload v-if="preId == 2" ref="uploadRef" class="upload-demo" :action="assetUploadUrl" method="put"
            :auto-upload="false" :limit="8" multiple :on-preview="handlePreview" :on-remove="handleRemove"
            :before-remove="beforeRemove" :on-change="uploadChange" :on-exceed="handleExceed"
            :before-upload="beforeUpload" :on-success="handleAvatarSuccess">
            <div class="assetCont_top_rightCont_upload" @click="uploadBtn"></div>
          </el-upload>
          <div v-if="preId == 1" class="assetCont_top_rightCont_upload" @click="uploadBtn"></div>
        </div>

        <div v-else class="assetCont_top_rightCont_sure">
          <div class="cancel" @click="cancelBtn">取消</div>
          <div class="sure active" @click="delBtn">删除</div>
          <div class="sure active" @click="sureBtn">添加</div>
        </div>
        <div class="assetCont_top_rightCont_searchInput">
          <el-input v-model="searchStr" class="input-with-select" type="text" placeholder="资产ID" clearable />
          <el-icon :size="13" color="#3f3f3f" class="assetCont_top_rightCont_searchInput_icon">
            <Search />
          </el-icon>
        </div>
      </div>
    </div>

    <el-scrollbar height="76%" wrap-class="assetCont_listCont">
      <!-- <ul class="assetCont_listCont"> -->
      <div class="assetCont_listCont_li" v-for="(item, index) in listArr.list" :key="index" @click="goEdit(item)">
        <img :src="item.url" alt="" />
        <div class="name">{{ item.filename }}</div>
        <div class="rect" v-show="item.boo">
          <el-icon class="rect_check" @click="closeAssetPop" size="16" color="#000000">
            <Check />
          </el-icon>
        </div>
      </div>
      <!-- </ul> -->
    </el-scrollbar>
  </div>
</template>

<script>
import { ref, getCurrentInstance, reactive } from 'vue';
import { ElButton, ElIcon, ElMessage } from 'element-plus';
import { getAssetList, getTemplateList, addAsset } from '@/api/index';
import mode from '../../api/modeType';

export default {
  name: '',
  data() {
    return {};
  },

  components: {},
  emits: ['closeAssetPop', 'assetUploadBtn', 'closeDelAssetPop'],

  setup(props, ctx) {
    const { proxy } = getCurrentInstance();
    const preId = ref(1);
    const searchStr = ref('');
    const isEdit = ref(false);
    const assetUploadUrl = ref('');

    let fileList = [];
    let selectTol = 0;
    let preItem = null;

    const listArr = reactive({
      list: [
        { filename: 'ethan', img: require('@/assets/images/home_bg1.jpg') },
        {
          filename: 'Shan的空间',
          img: require('@/assets/images/home_bg2.jpg'),
        },
        { filename: '画廊', img: require('@/assets/images/home_bg3.jpg') },
        {
          filename: 'Shan的数字空间',
          img: require('@/assets/images/home_bg4.jpg'),
        },
        {
          filename: 'Shan的3D场所',
          img: require('@/assets/images/home_bg5.jpg'),
        },
        {
          filename: 'Shan的空间',
          img: require('@/assets/images/home_bg6.jpg'),
        },
        { filename: 'asd', img: require('@/assets/images/home_bg7.jpg') },
        {
          filename: 'Shan的3D场所',
          img: require('@/assets/images/home_bg8.jpg'),
        },
        //   {name:'空间NFT',img:require('@//assets/images/home_bg3.jpg')}
      ],
    });
    function initCont(_id) {
      isEdit.value = false;

      selectTol = 0;

      proxy.$refs[`btn${_id}`].classList.add('active');
      proxy.$refs[`btn${2}`].classList.remove('active');

      preId.value = _id;

      if (_id == 1) {
        listArr.list = [
          { filename: 'ethan', img: require('@/assets/images/home_bg1.jpg') },
          {
            filename: 'Shan的空间',
            img: require('@/assets/images/home_bg2.jpg'),
          },
          { filename: '画廊', img: require('@/assets/images/home_bg3.jpg') },
          {
            filename: 'Shan的数字空间',
            img: require('@/assets/images/home_bg4.jpg'),
          },
          {
            filename: 'Shan的3D场所',
            img: require('@/assets/images/home_bg5.jpg'),
          },
          {
            filename: 'Shan的空间',
            img: require('@/assets/images/home_bg6.jpg'),
          },
          { filename: 'asd', img: require('@/assets/images/home_bg7.jpg') },
          {
            filename: 'Shan的3D场所',
            img: require('@/assets/images/home_bg8.jpg'),
          },
        ];
      }
    }
    function goEdit(_item) {
      if (_item.boo) {
        _item.boo = false;
        selectTol--;
        preItem = null;
      } else {
        if (selectTol != mode.maxSelectNum) {
          _item.boo = true;
          selectTol++;
        } else {
          _item.boo = true;
          if (preItem) preItem.boo = false;

          //  return
        }
        preItem = _item;
      }

      if (selectTol != 0) isEdit.value = true;
      else isEdit.value = false;

      //  ctx.emit('addAsset',_url)
    }
    function cancelBtn() {
      isEdit.value = false;
      if (preId.value == 2) {
        selectTol = 0;
        listArr.list.forEach((element) => {
          element.boo = false;
        });
      }
    }
    function sureBtn() {
      isEdit.value = false;
      mode.addAssetArr = [];
      listArr.list.forEach((element) => {
        if (element.boo) {
          mode.addAssetArr.push(element);
        }
      });
      menuBtn(1);
      ctx.emit('closeAssetPop', true);
    }
    function delBtn() {
      mode.addAssetArr = [];
      listArr.list.forEach((element) => {
        if (element.boo) {
          mode.addAssetArr.push(element);
        }
      });
      ctx.emit('closeDelAssetPop');
    }
    function closeAssetPop() {
      ctx.emit('closeAssetPop', false);
    }
    function uploadBtn() {
      if (fileList) proxy.$refs.uploadRef.clearFiles();
      fileList = [];

      console.log('uploadBtnuploadBtn');
      ctx.emit('assetUploadBtn', preId.value);
    }
    function menuBtn(_id) {
      if (preId.value == _id) return;

      isEdit.value = false;

      console.log(preId.value, '444');
      console.log(_id, '3333');

      proxy.$refs[`btn${_id}`].classList.add('active');
      if (preId.value != -1)
        proxy.$refs[`btn${preId.value}`].classList.remove('active');
      preId.value = _id;

      if (_id == 1) {
        listArr.list = [
          { filename: 'ethan', img: require('@/assets/images/home_bg1.jpg') },
          {
            filename: 'Shan的空间',
            img: require('@/assets/images/home_bg2.jpg'),
          },
          { filename: '画廊', img: require('@/assets/images/home_bg3.jpg') },
          {
            filename: 'Shan的数字空间',
            img: require('@/assets/images/home_bg4.jpg'),
          },
          {
            filename: 'Shan的3D场所',
            img: require('@/assets/images/home_bg5.jpg'),
          },
          {
            filename: 'Shan的空间',
            img: require('@/assets/images/home_bg6.jpg'),
          },
          { filename: 'asd', img: require('@/assets/images/home_bg7.jpg') },
          {
            filename: 'Shan的3D场所',
            img: require('@/assets/images/home_bg8.jpg'),
          },
        ];
      }
      if (_id == 2) {
        selectTol = 0;
        getAssetListLocal();
      }
    }

    function handlePreview(uploadFile) {
      console.log(uploadFile);
    }
    function handleRemove(file, uploadFiles) {
      console.log(file, uploadFiles);
    }
    function beforeRemove(uploadFile, uploadFiles) {}
    function handleExceed(files, uploadFiles) {
      console.log(files, uploadFiles);

      ElMessage.warning(
        `限制为8, 您这次选择了 ${files.length} 个文件`
      )
    }
    function assetUploadBtn(_id) {
      console.log(_id, 'assetUploadBtn');
    }
    function uploadChange(files, uploadFiles) {
      console.log('uploadChange');
      console.log(files,'files');
      console.log(uploadFiles,'uploadFiles');
      
      fileList = files;

      const name = fileList.name.split('.').pop();
      const str = name.toLowerCase();
      if (
        str == 'gif' ||
        str == 'png' ||
        str == 'jpg' ||
        str == 'jpeg' ||
        str == 'svg'
      ) {
      } else {
        ElMessage({
          message: '上传格式不对!只支持jpg jpeg png',
          type: 'warning',
        });
        return;
      }
      //  console.log(name,'name')
      //  console.log(name.toLowerCase(),'name.toLowerCase()')
      let subtypeStr = 0;
      let contentType = '';
      if (str == 'jpg' || str == 'jpeg') {
        subtypeStr = 1;
        contentType = 'image/jpeg';
      }
      if (str == 'png') {
        subtypeStr = 2;
        contentType = 'image/png';
      }
      if (str == 'svg') {
        subtypeStr = 3;
        contentType = 'image/svg';
      }
      if (str == 'gif') {
        subtypeStr = 4;
        contentType = 'image/gif';
      }

      const json = {
        filename: fileList.name,
        filesize: fileList.size,
        type: 1,
        subtype: subtypeStr,
        title: '美丽新世界',
        description: '这副作品',
        // thumbnail: "This is thumbnail",
        // thumbnailSize:'',
        // thumbnailType:''
      };
      console.log(json, 'json');
      addAssetF(json, fileList, contentType)
      
    }


    function addAssetF(json, _fileList, contentType) { 
      addAsset(json)
        .then((data) => {
          console.log(data, 'addAsset');

          assetUploadUrl.value = data.assetUploadUrl;
          const xhr = new XMLHttpRequest();
          const url = assetUploadUrl.value;
          console.log(url, 'url');
          xhr.open('put', url, true);
          xhr.setRequestHeader('Content-Type', contentType);
          // xhr.setRequestHeader('Content-Length','3333');
          xhr.onreadystatechange = function () {
            console.log(this,'上传成功688888');
            if (this.readyState === XMLHttpRequest.DONE) {
              if (this.status === 200) {
                console.log('上传成功6666');
                getAssetListLocal();
                ElMessage({
                  message: '上传成功!',
                  type: 'success',
                });

                if (_fileList) proxy.$refs.uploadRef.clearFiles();
                fileList = [];
              } else {
                console.log(this.status, this.statusText);
              }
            }
          };
          console.log(_fileList.raw, 'fileList.raw')
          xhr.send(_fileList.raw);
        })
        .catch((err) => { });
    }

    function handleAvatarSuccess(res, file) {
      // this.myData= file;
      console.log('上传成功11');
      proxy.$refs.uploadRef.clearFiles(); // 上传成功之后清除历史记录
      console.log('上传成功222');
    }
    function beforeUpload() {
      console.log('beforeUpload');
    }

    function getAssetListLocal() {
      getAssetList({ page: 0, pagesize: 100, type: 1 })
        .then((data) => {
          console.log(data, 'getAssetList');

          data.results.forEach((element) => {
            element.url = mode.assetUrl + element.url;
            element.boo = false;
          });
          listArr.list = data.results;
          console.log(listArr.list, 'getAssetList1111');
        })
        .catch((err) => {});
    }

    return {
      listArr,
      closeAssetPop,
      menuBtn,
      preId,
      searchStr,
      goEdit,
      isEdit,
      cancelBtn,
      sureBtn,
      delBtn,
      uploadBtn,
      handlePreview,
      handleRemove,
      beforeRemove,
      handleExceed,
      uploadChange,
      beforeUpload,
      handleAvatarSuccess,
      initCont,
      getAssetListLocal,
    };
  },

  props: {},

  computed: {},

  watch: {},

  methods: {},

  created() {}, // 生命周期 - 创建完成（可以访问当前this实例）
  mounted() {}, // 生命周期 - 挂载完成（可以访问DOM元素
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss">
@import '@/assets/css/mixin';
.assetCont_listCont {
  margin: auto;
  left: 0;
  right: 0;
  margin-top: 16px;
  width: 93%;
  height: 80%;
  // overflow-y: scroll;
  // overflow-x:visible;

  .el-scrollbar__view {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  &_li {
    cursor: pointer;
    width: 29%;
    position: relative;
    // height: 230px;
    // border-radius: 10px;
    // background: rgb(199, 199, 199);
    margin: 10px 2%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    text-align: left;

    img {
      width: 100%;
      height: 130px;
      border-radius: 13px;
      object-fit: cover;
      background: rgba(219, 219, 219, 0.6);
    }

    .rect {
      position: absolute;
      top: 3%;
      left: 3%;
      width: 18px;
      height: 18px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #6b69ff;

      &_check {
      }
    }

    .name {
      margin: 10px 0 0 10px;
      color: black;
      font-size: 14px;
      font-weight: bold;
      @include clamp(1);
      @include no-wrap();
    }
  }
}

.el-upload-list {
  display: none;
}
.assetCont_top_rightCont_searchInput {
  .el-input__wrapper {
    border-radius: 30px;
    background: linear-gradient(9deg, #ffffff 0%, #c7d3ff 100%);
  }
  .el-input {
    height: 100%;

    input {
      padding-left: 26px;
    }
  }
}
</style>
<style lang="scss" scoped>
@import '@/assets/css/mixin';
.upload-demo {
  position: absolute;
  left: 0;
  top: 0;
}
.cont {
  height: fit-content;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  width: 45px;
  height: 30px;
}

.assetCont {
  width: 80%;
  height: 66%;
  background: url('../../assets/images/pop-bg.png') no-repeat center;
  background-size: cover;
  // background: white;
  border-radius: 20px;
  border: 1px solid rgba(219, 219, 219, 0.6);
  z-index: 301;
  overflow-x: visible;
  @include center();

  position: fixed;
  display: none;
  &_close {
    position: absolute;
    right: 2%;
    top: 3%;
    cursor: pointer;
    font-size: 14px;
    color: rgb(53, 53, 53);
  }

  &_title {
    color: black;
    font-size: 21px;
    font-weight: bold;
    margin-top: 23px;
  }
  .assetCont_top {
    margin: auto;
    margin-top: 2%;
    height: fit-content;
    width: 93%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &_rightCont {
      height: fit-content;
      width: 88%;
      color: white;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      margin-right: 10px;
      &_sure {
        justify-content: center;
        align-items: center;
        position: relative;
        display: flex;
        margin-right: 13px;
        font-size: 14px;

        > div {
          padding: 4px 16px;
          // margin: 0 6px;
          cursor: pointer;
          border-radius: 22px;
          position: relative;
          color: #1e0645;

          border-radius: 12px;

          // border: 3px solid ;
          // border-image:linear-gradient(to right, #8f41e9, #578aef) 10;
        }
        .active {
          background: #6b69ff;
          color: white;
          margin: 0 3px;
        }
      }
      &_upload {
        width: 30px;
        height: 30px;
        background: url('@/assets/images/icon-save1@2x.png') no-repeat center;
        background-size: contain;
        cursor: pointer;
        margin-right: 13px;
      }
      &_searchInput {
        position: relative;
        width: 200px;
        height: fit-content;
        color: #3f3f3f;
        font-size: 14px;

        &_icon {
          position: absolute;
          left: 8%;
          top: 50%;
          transform: translate(0%, -50%);
        }
      }
    }
    &_menu {
      font-size: 14px;
      color: rgb(53, 53, 53);
      font-weight: bold;
      height: fit-content;
      width: 50%;
      color: white;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      &_icon {
        padding: 10px 30px;
        margin: 0 6px;
        cursor: pointer;
        border-radius: 22px;
        position: relative;
        color: #1e0645;

        border-radius: 14px;

        // border: 3px solid ;
        // border-image:linear-gradient(to right, #8f41e9, #578aef) 10;
      }
      .active {
        background: rgb(221, 221, 221);
        background: linear-gradient(112deg, #b224ef 0%, #7579ff 100%);
        box-shadow: 0px 10px 20px 1px rgba(110, 75, 236, 0.2);
        span {
          color: white;
          -webkit-text-fill-color: white;
        }
      }

      span {
        //    background: linear-gradient(112deg, #B224EF 0%, #7579FF 100%);
        //    background-clip: text;
        //    -webkit-text-fill-color: transparent;
        //    position: relative;
      }
    }
  }
}
</style>
