<template>
  <div class="main">
    <router-view />
  </div>
</template>

<script>
import { ref, getCurrentInstance, reactive } from 'vue';
import mode from '@/api/modeType';

export default {
  name: '',
  data() {
    return {};
  },

  components: {},
  setup(props, ctx) {
  

    return { };
  },
  props: {},

  computed: {},

  watch: {},

  methods: {},

  created() { }, // 生命周期 - 创建完成（可以访问当前this实例）
  mounted() {
   

   }, // 生命周期 - 挂载完成（可以访问DOM元素
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #f2f2f2;
  // display: none;
}

.main {
  width: 100%;
  height: 100%;

  // background: #F2F2F2;
}
</style>
