import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './assets/css/base.scss';

// import 'element-plus/dist/index.css'

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'element-plus/es/components/message/style/css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

const app = createApp(App).use(store).use(router);
app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.mount('#app');
// app.use(ElementPlus)
