<!--  -->
<template>
  <div class="home">
    <div class="homeTop">
      <div class="homeTop_leftCont">
        <div class="homeTop_title">Zetaverse</div>

        <div class="homeTop_logo"></div>

        <div class="homeTop_menu">
          <p :class="{ menuClickClass: preId == 1 }" @click="menuBtn(1, true)">
            探索
          </p>
          <p :class="{ menuClickClass: preId == 2 }" @click="menuBtn(2, true)">
            创建
          </p>
          <p :class="{ menuClickClass: preId == 3 }" @click="menuBtn(3, true)">
            管理
          </p>
          <span class="homeTop_menu_line"></span>
        </div>
      </div>

      <div class="homeTop_rightCont">
        <!-- <div class="homeTop_about">
          后台管理
        </div> -->
        <div class="homeTop_sign" v-if="!userInfoVisible">登录</div>
        <div class="homeTop_userInfo" @click="changeAdmin" v-else>
          <span></span>
          <img
            class="homeTop_userInfo_icon"
            src="../assets/images/icon.jpg"
            alt=""
          />
          <p class="homeTop_userInfo_name">ethan Qwoeoasdl</p>

          <div class="homeTop_userInfo_sub">
            <p>ethan Qwoeoasdl</p>
            <p @click.stop="digitalBtn()">数字人</p>
            <p @click.stop="accountInfoBtn()">账号信息</p>
            <p @click.stop="logOutBtn()">退出登录</p>
          </div>
        </div>
      </div>
    </div>

    <div class="homeMiddle">
      <div class="spacelist" v-if="preId != 3">
        <div class="homeMiddle_top">
          <div class="homeMiddle_searchInput">
            <el-input
              v-model="searchStr"
              class="input-with-select"
              type="text"
              placeholder="搜索展厅"
              clearable
            />
            <el-icon
              :size="16"
              color="#c7c7c7"
              class="homeMiddle_searchInput_icon"
            >
              <Search />
            </el-icon>
          </div>

          <!-- <div class="homeMiddle_userInfo">
           <img class="homeMiddle_userInfo_icon"  src="../assets/images/icon.jpg" alt="">
        </div> -->
          <div
            class="homeMiddle_addNewSpace"
            v-if="isAddNew"
            @click="addNewSpace"
          >
            <span>+</span> 添加新空间
          </div>
        </div>

        <div class="homeMiddle_title">
          <span>{{ preId == 1 ? title1 : title2 }}</span>
        </div>

        <!-- <div v-else class="homeMiddle_menu">
           <div class="homeMiddle_menu_icon active" ref="btn1" @click="menuBtn(1)"><span></span><span>探索</span>  </div>
           <div class="homeMiddle_menu_icon" ref="btn2" @click="menuBtn(2)"><span></span><span>空间</span></div>
         </div> -->

        <div class="homeMiddle_viewIcon">
          <div class="homeMiddle_viewIcon_btn1"></div>
          <div class="homeMiddle_viewIcon_btn2"></div>
        </div>

        <ul class="homeMiddle_listCont" v-if="createListArr.list.length > 0">
          <li
            v-for="(item, index) in createListArr.list"
            :key="index"
            @click.passive="goSpace(item)"
          >
            <img :src="item.thumbnail" alt="" />
            <div class="name">{{ item.name || '无' }}</div>
            <div class="playerTol">
              <span></span
              ><span>
                {{ preId == 1 ? 'Shuang，谭霖 +2更多' : item.updateTime }}</span
              >
            </div>

            <div class="editCont" v-if="preId == 2">
              <span></span>
              <p>...</p>
              <div class="editCont_sub">
                <p @click.stop="copyLink(item)">复制链接</p>
                <p @click.stop="reName(item)">重命名</p>
                <p @click.stop="delSpaceBtn(item)">删除</p>
              </div>
            </div>
          </li>
        </ul>

        <div class="homeMiddle_tip" v-else>暂无内容</div>
      </div>

      <div class="administratorCont" v-if="preId == 3">
        <AdminView></AdminView>
      </div>
    </div>

    <div class="spacePop">
      <el-icon
        class="spacePop_close"
        @click="closeSpacePop"
        size="16"
        color="#000000"
      >
        <CloseBold />
      </el-icon>
      <div class="spacePop_title">选择场景模版</div>

      <ul class="spacePop_listCont">
        <li
          v-for="(item, index) in TemplateListArr.list"
          :key="index"
          @click="tempGoSpace(item)"
        >
          <img :src="item.thumbnail" alt="" />
          <div class="name">{{ item.name }}</div>
        </li>
      </ul>
    </div>
    <div class="bgPop"></div>

    <div class="tipPop">
      <div class="tipPop_title" v-html="tipMsg"></div>
      <input v-model="spaceNameStr" class="tipPop_input" type="text" />
      <div class="tipPop_menu">
        <span @click="tipPopClose">取消</span>
        <span @click="tipPopConfirm">确认</span>
      </div>
    </div>

    <AssetView></AssetView>
    <!-- :headers= "{'Content-Type':'application/octet-stream'}" -->

    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import { ref, getCurrentInstance, reactive, watch } from 'vue';
import { TweenMax, Power4 } from 'gsap';
import {
  getTemplateList,
  getSpacesList,
  createSpace,
  updateSpaceName,
  delSpace,
  getSpaceInfo,
  getExploreSpacesList,
  getSearchSpaceList,
  getSearchExploreSpaceList,
  getAgroaToken,
} from '@/api/index';
import { ElMessage } from 'element-plus';
import AssetView from '@/components/spaceView/assetView.vue';
import AdminView from '@/components/home/adminView.vue';
import mode from '@/api/modeType';
import { formDate } from '@/tools/tools';

export default {
  data() {
    return {
      title: '展厅名称',
      title1: '进入展厅，体验沉浸式虚拟看展的乐趣吧！',
      title2: '使用高效的编辑工具，创造独特的展厅吧！',
    };
  },

  components: {
    AssetView,
    AdminView,
  },

  props: {},

  computed: {},

  watch: {},

  setup() {
    const { proxy } = getCurrentInstance();
    const userInfoVisible = ref(true);
    const searchStr = ref('');
    const isAdmin = ref(true);
    const spacePop = ref(false);
    const preId = ref(1);
    const isAddNew = ref(false);
    const assetUploadUrl = ref('');
    const spaceNameStr = ref('');

    const tipMsg = ref('重命名你的Space');
    const tipArr = ['请输入新的展厅名称', '确定要删除吗?', '请输入展厅名称'];

    const fileList = [];

    const tempJsom = reactive({
      obj: {},
    });

    const TemplateListArr = reactive({
      list: [
        { name: 'ethan', img: require('@/assets/images/home_bg1.jpg') },
        { name: 'Shan的空间', img: require('@/assets/images/home_bg2.jpg') },
        { name: '画廊', img: require('@/assets/images/home_bg3.jpg') },
        {
          name: 'Shan的数字空间',
          img: require('@/assets/images/home_bg4.jpg'),
        },
        { name: 'Shan的3D场所', img: require('@/assets/images/home_bg5.jpg') },
        { name: 'Shan的空间', img: require('@/assets/images/home_bg6.jpg') },
        { name: 'asd', img: require('@/assets/images/home_bg7.jpg') },
        { name: 'Shan的3D场所', img: require('@/assets/images/home_bg8.jpg') },
        { name: '空间NFT', img: require('@//assets/images/home_bg3.jpg') },
      ],
    });

    const exploreListArr = reactive({
      list: [
        { name: 'ethan', img: require('@/assets/images/home_bg1.jpg') },
        { name: 'Shan的空间', img: require('@/assets/images/home_bg2.jpg') },
        { name: '画廊', img: require('@/assets/images/home_bg3.jpg') },
        {
          name: 'Shan的数字空间',
          img: require('@/assets/images/home_bg4.jpg'),
        },
        { name: 'Shan的3D场所', img: require('@/assets/images/home_bg5.jpg') },
        { name: 'Shan的空间', img: require('@/assets/images/home_bg6.jpg') },
        { name: 'asd', img: require('@/assets/images/home_bg7.jpg') },
        { name: 'Shan的3D场所', img: require('@/assets/images/home_bg8.jpg') },
        { name: '空间NFT', img: require('@//assets/images/home_bg3.jpg') },
      ],
    });

    const createListArr = reactive({
      list: [
        { name: 'ethan', thumbnail: require('@/assets/images/home_bg1.jpg') },
        {
          name: 'Shan的空间',
          thumbnail: require('@/assets/images/home_bg2.jpg'),
        },
        { name: '画廊', thumbnail: require('@/assets/images/home_bg3.jpg') },
        {
          name: 'Shan的数字空间',
          thumbnail: require('@/assets/images/home_bg4.jpg'),
        },
        {
          name: 'Shan的3D场所',
          thumbnail: require('@/assets/images/home_bg5.jpg'),
        },
        {
          name: 'Shan的空间',
          thumbnail: require('@/assets/images/home_bg6.jpg'),
        },
        { name: 'asd', thumbnail: require('@/assets/images/home_bg7.jpg') },
        {
          name: 'Shan的3D场所',
          thumbnail: require('@/assets/images/home_bg8.jpg'),
        },
        {
          name: '空间NFT',
          thumbnail: require('@//assets/images/home_bg3.jpg'),
        },
      ],
    });

    function submitUpload() {
      const fd = new FormData();
      fd.append('name', fileList.name);
      fd.append('files', fileList.raw);

      const name = fileList.name.split('.').pop();
      console.log(name, 'name');
      console.log(name.toLowerCase(), 'name.toLowerCase()');
      //  axios.put(assetUploadUrl.value, fileList.raw).then(function (response) {
      //       console.log(response.data)
      //     })
      //     .catch(function (error) {
      //     })
      const xhr = new XMLHttpRequest();
      const url = assetUploadUrl.value;
      xhr.open('put', url, true);
      xhr.setRequestHeader('Content-Type', 'audio/mp3');
      // xhr.setRequestHeader('Content-Length','3333');
      xhr.onreadystatechange = function () {
        if (this.readyState === XMLHttpRequest.DONE) {
          if (this.status === 200) {
            this.customerArray = JSON.parse(this.responseText);
          } else {
            console.log(this.status, this.statusText);
          }
        }
      };

      xhr.send(fileList.raw);
    }

    watch(searchStr, (newValue, oldValue) => {
      console.log('searchStr ==> ', newValue, oldValue);
      if (preId.value == 1) {
        if (newValue != '') {
          getSearchExploreSpaceList({
            key: newValue,
            limit: 50,
            order: 'updatetime',
          })
            .then((data) => {
              console.log(data, 'getSearchSpaceList');
              if (data == null) {
                console.log(111111);

                createListArr.list = [];
              } else {
                console.log(222222);
                data.forEach((element) => {
                  element.updateTime = formDate(
                    new Date(element.updateTime),
                    'yyyy-MM-DD HH:mm:ss',
                  );
                });
                createListArr.list = data;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          getExploreSpacesListF();
        }
      }

      if (preId.value == 2) {
        console.log(newValue != '');
        if (newValue != '') {
          getSearchSpaceList({ key: newValue, limit: 50, order: 'updatetime' })
            .then((data) => {
              console.log(data, 'getSearchSpaceList');
              if (data == null) {
                console.log(111111);

                createListArr.list = [];
              } else {
                console.log(222222);
                data.forEach((element) => {
                  element.updateTime = formDate(
                    new Date(element.updateTime),
                    'yyyy-MM-DD HH:mm:ss',
                  );
                });
                createListArr.list = data;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          getSpacesList1();
        }
      }
    });

    function changeAdmin() {}

    function addNewSpace() {
      getTemplateList({ page: 0, pagesize: 118 })
        .then((data) => {
          // if(data.code==200){
          console.log(data, 'getTemplateList');
          TemplateListArr.list = data.results;

          initAni('bgPop', true);
          initAni('spacePop', false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    function closeSpacePop() {
      endAni('bgPop');
      endAni('spacePop');
    }

    function goSpace(_item) {
      // proxy.$router.push('spaceView')
      // if(preId.value==1)
      //   return
      mode.uuid = _item.uuid;
      mode.user = _item.user;
      mode.info = _item;

      if (preId.value == 1) mode.seeMode = 0;
      if (preId.value == 2) mode.seeMode = 1;
      console.log(mode.info, 'mode.info');

      mode.user = Math.random(320, 20304);
      getAgroaToken()
        .then((tokenData) => {
          // if(data.code==200){
          console.log(tokenData, 'getAgroaToken');
          mode.AgroaInfo = tokenData;
          mode.user = _item.user;
          getSpaceInfo()
            .then((data) => {
              // if(data.code==200){
              console.log(data, 'getSpaceInfo');
              mode.pack = data.template;
              if (preId.value == 1)
                proxy.$router.replace({ path: 'spacePreview' });
              else proxy.$router.replace({ path: 'spaceView' });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    function tempGoSpace(_item) {
      // proxy.$router.push('spaceView')

      endAni('bgPop');
      endAni('spacePop');

      tipMsg.value = tipArr[2];
      spaceNameStr.value = '';
      document.getElementsByClassName('tipPop')[0].style.height = '197px';
      document.getElementsByClassName('tipPop_input')[0].style.display =
        'block';
      initAni('tipPop', false);

      mode.pack = _item.template;

      console.log(mode.pack, 'mode.packmode.pack');

      tempJsom.obj = _item;
      //  createSpace({name:_item.name,template:_item.template,thumbnail:_item.thumbnail})
      //         .then((data) => {
      //           // if(data.code==200){
      //             console.log(data,'createSpace')
      //             mode.uuid=data.uuid
      //             mode.user='a862f9c'
      //             mode.info=_item
      //             console.log(mode.info,'mode.info111')
      //             proxy.$router.replace({ path:'spaceView'})

      //         })
      //         .catch((err) => {
      //           console.log(err)
      //         })
    }

    function menuBtn(_id, _boo) {
      if (preId.value == _id) return;

      const dx = [0, 62, 122];

      if (_boo) mode.adminMenuId = 0;

      TweenMax.to('.homeTop_menu_line', 0.6, {
        x: dx[_id - 1],
        ease: Power4.easeOut,
        delay: 0,
        onComplete: () => {},
      });

      console.log(dx[_id - 1], 'dx[_id-1]');
      //  proxy.$refs["btn"+_id].classList.add('active')
      //  if(preId.value!=-1)
      //     proxy.$refs["btn"+preId.value].classList.remove('active')
      preId.value = _id;
      mode.homeMenuNum = _id;
      searchStr.value = '';
      if (_id == 1) {
        isAddNew.value = false;
        getExploreSpacesListF();

        document.getElementsByClassName('homeMiddle')[0].style.overflow =
          'auto';
      }
      if (_id == 2) {
        document.getElementsByClassName('homeMiddle')[0].style.overflow =
          'auto';
        isAddNew.value = true;
        getSpacesList1();
      }

      if (_id == 3) {
        document.getElementsByClassName('homeMiddle')[0].style.overflow =
          'hidden';
      }
    }

    function getExploreSpacesListF() {
      getExploreSpacesList({ page: 0, pagesize: 118, order: 'updatetime' })
        .then((data) => {
          // if(data.code==200){
          console.log(data, 'getExploreSpacesList');

          data.results.forEach((element) => {
            element.updateTime = formDate(
              new Date(element.updateTime),
              'yyyy-MM-DD HH:mm:ss',
            );
          });
          createListArr.list = data.results;

          // createListArr.list=data.results.reverse()
        })
        .catch((err) => {
          console.log(err);
        });
    }
    function getSpacesList1() {
      getSpacesList({ page: 0, pagesize: 118, order: 'updatetime' })
        .then((data) => {
          // if(data.code==200){
          console.log(data, 'getSpacesList');
          if (!data.results)
            createListArr.list=[]

          data.results.forEach((element) => {
            element.updateTime = formDate(
              new Date(element.updateTime),
              'yyyy-MM-DD HH:mm:ss',
            );
          });
          createListArr.list = data.results;

          // createListArr.list=data.results.reverse()
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // function menuBtn(_id){
    //    if(preId.value==_id)
    //    return

    //   console.log(preId.value,'444')
    //   console.log(proxy.$refs["btn"+_id],'3333')

    //    proxy.$refs["btn"+_id].classList.add('active')
    //    if(preId.value!=-1)
    //       proxy.$refs["btn"+preId.value].classList.remove('active')
    //    preId.value=_id
    //    if(_id==1){
    //      isAddNew.value=false

    //    }
    //    if(_id==2){
    //      isAddNew.value=true
    //    }

    // }

    //  mode.assetID='62b6869156d6ac9badba2eb9'
    //  getAssetInfo()
    //           .then((data) => {
    //               console.log(data,'getAssetInfo')

    //           })
    //           .catch((err) => {
    //             console.log(err)
    //           })

    function initAni(_str, _boo) {
      document.getElementsByClassName(_str)[0].style.display = 'block';
      if (_boo) {
        TweenMax.to(`.${_str}`, 0, { alpha: 0 });
        TweenMax.to(`.${_str}`, 0.6, {
          alpha: 1,
          delay: 0.3,
          onComplete: () => {},
        });
      } else {
        TweenMax.to(`.${_str}`, 0, { alpha: 1 });
        TweenMax.from(`.${_str}`, 0.6, {
          alpha: 0,
          y: 160,
          ease: Power4.easeOut,
          delay: 0.6,
          onComplete: () => {},
        });
      }
    }
    function endAni(_str) {
      TweenMax.to(`.${_str}`, 0.3, {
        alpha: 0,
        delay: 0,
        onComplete: () => {
          document.getElementsByClassName(_str)[0].style.display = 'none';
        },
      });
    }

    function tipPopClose() {
      endAni('tipPop');
    }
    function tipPopConfirm() {
      // 重命名空间
      if (tipMsg.value == tipArr[0]) {
        if (spaceNameStr.value.length == 0) {
          ElMessage({
            message: '请输入名字！',
            type: 'warning',
          });
          return;
        }
        //
        updateSpaceName({ name: spaceNameStr.value })
          .then((data) => {
            console.log(data, 'updateSpaceName');
            ElMessage({
              message: '修改成功！',
              type: 'success',
            });
            getSpacesList1();
          })
          .catch((err) => {
            console.log(err);
          });
      }

      // 删除空间
      if (tipMsg.value == tipArr[1]) {
        console.log(mode.uuid, 'item.uuid111');
        
        delSpace()
          .then((data) => {
            console.log(data, 'delSpace');
            ElMessage({
              message: '删除成功！',
              type: 'success',
            });
            getSpacesList1();
          })
          .catch((err) => {
            console.log(err);
          });
      }

      // 新建空间
      if (tipMsg.value == tipArr[2]) {
        createSpace({
          name: spaceNameStr.value,
          template: tempJsom.obj.template,
          thumbnail: tempJsom.obj.thumbnail,
        })
          .then((data) => {
            // if(data.code==200){
            mode.seeMode = 1;
            console.log(data, 'createSpace');
            mode.uuid = data.uuid;
            // mode.user = 'a862f9c';
            tempJsom.obj.name = spaceNameStr.value;
            mode.info = tempJsom.obj;
            console.log(mode.info, 'mode.info111');
            proxy.$router.replace({ path: 'spaceView' });
          })
          .catch((err) => {
            console.log(err);
          });
      }

      endAni('tipPop');
    }
    function copyLink(item) {
      console.log(item);
    }
    function reName(item) {
      console.log(item);
      tipMsg.value = tipArr[0];
      spaceNameStr.value = '';
      mode.uuid = item.uuid;
      document.getElementsByClassName('tipPop')[0].style.height = '197px';
      document.getElementsByClassName('tipPop_input')[0].style.display =
        'block';
      initAni('tipPop', false);
    }
    function delSpaceBtn(item) {
      console.log(item, 'item');
      console.log(item.uuid, 'item.uuid');

      if (item.status == 2) { 
        ElMessage({
          message: '该展厅已在“探索”上架，无法删除，请联系管理员将该展厅下架后再删除。',
          type: 'warning',
        });
        
        return
      }

      mode.uuid = item.uuid;
      tipMsg.value = tipArr[1];
      document.getElementsByClassName('tipPop')[0].style.height = '137px';
      document.getElementsByClassName('tipPop_input')[0].style.display = 'none';

      initAni('tipPop', false);
    }

    // TweenMax.to('.spacePop', .3, {alpha:0,delay:0})

    return {
      preId,
      userInfoVisible,
      searchStr,
      isAdmin,
      isAddNew,
      changeAdmin,
      TemplateListArr,
      createListArr,
      exploreListArr,
      addNewSpace,
      spacePop,
      closeSpacePop,
      goSpace,
      tempGoSpace,
      menuBtn,
      assetUploadUrl,
      fileList,
      submitUpload,
      tipMsg,
      tipArr,
      tipPopClose,
      tipPopConfirm,
      reName,
      delSpaceBtn,
      spaceNameStr,
      getSpacesList1,
      getExploreSpacesListF,
      copyLink,
    };
  },

  methods: {},

  created() {}, // 生命周期 - 创建完成（可以访问当前this实例）
  mounted() {
    document.getElementsByClassName('main')[0].style.overflow = 'auto';
    document.getElementsByClassName('bgPop')[0].style.display = 'none';
    document.getElementsByClassName('bgPop')[0].style.opacity = '0';

    // mode.homeMenuNum=3
    console.log(mode.homeMenuNum, 'mode.homeMenuNum');
    this.menuBtn(mode.homeMenuNum, false);

    //  alert('test')
    const dx = [0, 62, 122];

    TweenMax.to('.homeTop_menu_line', 0.1, {
      x: dx[mode.homeMenuNum - 1],
      ease: Power4.easeOut,
      delay: 0,
      onComplete: () => {},
    });

    if (mode.homeMenuNum == 1) {
      this.getExploreSpacesListF();
    } else {
      this.getSpacesList1();
    }
  }, // 生命周期 - 挂载完成（可以访问DOM元素
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss">
.homeMiddle_searchInput {
  .el-input__inner {
    font-size: 0.6rem;
  }
  .el-input__wrapper {
    border-radius: 30px;
  }
  .el-input {
    height: 100%;

    input {
      padding-left: 26px;
    }
  }
}
</style>
<style lang="scss" scoped>
@import '@/assets/css/mixin';

.home {
  width: 100%;
  height: 100%;
  .administratorCont {
    width: 100%;
    height: 100%;
    margin-top: 64px;
  }
  .tipPop {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 398px;
    height: 137px;
    background: white;
    border-radius: 20px;
    display: none;
    &_input {
      outline: none;
      border: none;
      border-bottom: 1px solid black;
      padding: 10px 0;
      margin: auto;
      margin-bottom: 20px;
    }
    &_title {
      font-size: 16px;
      font-weight: bold;
      color: #1e0645;
      margin-top: 28px;
      margin-bottom: 25px;
    }
    &_menu {
      font-size: 14px;
      color: #1e0645;

      span:nth-child(1) {
        margin: 0 16px;
        display: inline-flex;
        cursor: pointer;
        &:hover {
          color: #6b69ff;
        }
      }
      span:nth-child(2) {
        color: white;
        background: #6b69ff;
        border-radius: 12px;
        padding: 10px 30px;
        margin: 0 16px;
        box-sizing: border-box;
        display: inline-flex;
        cursor: pointer;
        &:hover {
          background: #4d4aeb;
        }
      }
    }
  }

  .bgPop {
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 300;
    display: none;
  }
  .spacePop {
    width: 80%;
    height: 66%;
    background: white;
    border-radius: 20px;
    z-index: 301;
    @include center();
    position: fixed;
    display: none;
    &_close {
      position: absolute;
      right: 2%;
      top: 3%;
      cursor: pointer;
    }

    &_title {
      color: black;
      font-size: 21px;
      font-weight: bold;
      margin-top: 23px;
    }

    &_listCont {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      margin: auto;
      margin-top: 26px;
      width: 93%;
      height: 80%;
      overflow-y: scroll;
      overflow-x: visible;

      li {
        cursor: pointer;
        width: 29%;
        // height: 230px;
        // border-radius: 10px;
        // background: rgb(199, 199, 199);
        margin: 10px 10px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        text-align: left;

        img {
          width: 100%;
          height: 130px;
          border-radius: 13px;
          object-fit: cover;
        }
        .name {
          margin: 10px 0 0 10px;
          color: black;
          font-size: 14px;
          font-weight: bold;
          @include clamp(1);
          @include no-wrap();
        }
      }
    }
  }

  .homeTop {
    width: 100%;
    height: 64px;
    background: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: #eeeded 10px 1px 11px 6px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    &_leftCont {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      width: 90%;
      margin-left: 3%;
    }
    &_rightCont {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      width: 10%;
      font-size: 14px;
      font-weight: bold;
      margin-right: 3%;
    }
    &_logo {
      width: 230px;
      height: 64px;
      background: url('../assets/images/logo.png') no-repeat center;
      background-size: contain;
      margin-left: 20px;
    }
    &_about {
      margin-right: 6%;
    }
    &_sign {
      width: 100px;
      text-align: left;
    }
    &_userInfo {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: fit-content;
      cursor: pointer;
      position: relative;

      > span {
        background: wheat;
        position: absolute;
        left: 0px;
        width: 80px;
        height: 86px;
        z-index: 0;
        opacity: 0.01;
      }

      &:hover {
        //  border: 1px solid rgb(141, 18, 18);
        > div {
          // opacity: 1;
          display: flex;
        }
      }

      &_sub {
        position: absolute;
        top: 56px;
        left: 0px;
        width: 80px;
        height: 120px;
        background: white;
        border: 1px solid white;
        font-size: 12px;
        font-weight: normal;
        // opacity: 0;

        display: flex;
        align-content: space-around;
        padding: 0 10px;
        flex-wrap: wrap;
        display: none;
        p {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 3px 0;
          @include clamp(1);
          @include no-wrap();
          font-weight: bold;
          &:hover {
            // background: white;
            background: linear-gradient(112deg, #b224ef 0%, #7579ff 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      &_icon {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 100%;
        border: 3px solid white;
        margin-right: 3px;
        box-sizing: border-box;
      }
      &_name {
        @include clamp(1);
        @include no-wrap();
        width: 60px;
      }
    }

    &_title {
      font-size: 32px;
      color: white;
      font-weight: bold;
      // margin-left: 6%;
      font-family: 'Poppins';

      width: fit-content;
      background: linear-gradient(112deg, #b224ef 0%, #7579ff 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      // background: url("../assets/images/logo.svg") no-repeat center;
      // background-size: cover;
    }
    &_menu {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 100%;
      font-size: 12px;
      color: white;
      font-weight: bold;
      margin-left: 3%;
      width: fit-content;
      // border-bottom: 3px solid linear-gradient(112deg, #B224EF 0%, #7579FF 100%);
      color: #535353;

      .menuClickClass {
        background: linear-gradient(112deg, #b224ef 0%, #2b30c0 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      p {
        margin: 0 16px;
        cursor: pointer;
        font-size: 14px;
        &:hover {
          background: linear-gradient(112deg, #b224ef 0%, #2b30c0 100%);
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      &_line {
        position: absolute;
        bottom: 0;
        left: 12px;
        width: 20%;
        height: 3px;
        background: linear-gradient(112deg, #b224ef 0%, #7579ff 100%);
      }
    }
  }

  .homeMiddle {
    position: relative;
    width: 100%;
    height: 100%;
    &_tip {
      margin-top: 10%;
      font-size: 20px;
    }
    //  margin-top: 100px;
    &_viewIcon {
      position: absolute;
      right: 5%;
      // top: 190px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_btn1 {
        width: 20px;
        height: 20px;
        background: url('../assets/images/home_btn1.png') no-repeat center;
        background-size: contain;
        margin: 0 10px;
        cursor: pointer;
      }
      &_btn2 {
        width: 20px;
        height: 20px;
        background: url('../assets/images/home_btn2.png') no-repeat center;
        background-size: contain;
        cursor: pointer;
      }
    }
    &_top {
      //  background: seagreen;
      position: relative;
      margin-top: 100px;
      //  display: flex;
      //  justify-content: space-between;
      //  align-content: flex-start;
    }
    &_searchInput {
      position: absolute;
      left: 4%;
      top: 16%;
      width: 14rem;
      height: 40px;
      font-size: 1rem;

      &_icon {
        position: absolute;
        left: 4%;
        top: 50%;
        transform: translate(0%, -50%);
      }
    }
    &_addNewSpace {
      position: absolute;
      right: 4%;
      top: 4%;
      width: 187px;
      height: 54px;
      background: linear-gradient(112deg, #b224ef 0%, #7579ff 100%);
      box-shadow: 0px 10px 20px 1px rgba(110, 75, 236, 0.2);
      border-radius: 22px 22px 22px 22px;
      opacity: 1;
      font-size: 20px;
      color: white;
      font-weight: bold;
      // line-height: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      span {
        font-size: 30px;
        margin-right: 6px;
      }
    }

    &_userInfo {
      width: 150px;
      height: 150px;
      border-radius: 100%;
      border: 3px solid white;
      overflow: hidden;
      box-shadow: #d8d8d8 1px 6px 11px 6px;
      margin: auto;
      margin-top: 40px;

      &_icon {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &_menu {
      font-size: 20px;
      color: rgb(53, 53, 53);
      font-weight: bold;
      margin-top: 20px;
      height: 80px;
      width: 100%;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &_icon {
        padding: 10px 36px;
        margin: 0 6px;
        cursor: pointer;
        border-radius: 14px;
        position: relative;

        background: rgb(221, 221, 221);
        background: linear-gradient(112deg, #b224ef 0%, #7579ff 100%);
        box-shadow: 0px 10px 20px 1px rgba(110, 75, 236, 0.2);
        border-radius: 14px;

        // border: 3px solid ;
        // border-image:linear-gradient(to right, #8f41e9, #578aef) 10;
      }
      .active {
        // border: 3px solid linear-gradient(112deg, #B224EF 0%, #7579FF 100%);

        span:nth-child(1) {
          width: 0%;
          height: 0%;
        }
        span:nth-child(2) {
          color: white;
          -webkit-text-fill-color: white;
        }
      }
      span:nth-child(1) {
        position: absolute;
        background: #e6e6e6;
        width: 96%;
        height: 90%;
        border-radius: 12px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      span:nth-child(2) {
        background: linear-gradient(112deg, #b224ef 0%, #7579ff 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        position: relative;
      }
    }
    &_title {
      // font-size: 48px;
      color: rgb(53, 53, 53);
      font-weight: bold;
      margin-top: 23px;
      height: 80px;

      // span:nth-child(1){
      //   font-size: 32px;
      //   color: #8460f0;

      // }
      span:nth-child(1) {
        font-size: 24px;
        color: #1e0645;
      }
    }

    &_listCont {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin: auto;
      margin-top: 30px;
      width: 93%;

      li {
        cursor: pointer;
        width: 31%;
        // height: 230px;
        // border-radius: 10px;
        // background: rgb(199, 199, 199);
        margin: 1rem 1%;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        text-align: left;
        position: relative;

        .editCont {
          position: absolute;
          background: rgba(177, 177, 177, 0.8);
          border: 1px solid rgb(187, 187, 187);
          border-radius: 100%;
          width: 20px;
          height: 20px;
          right: 3%;
          top: 3%;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          color: black;
          // z-index: 1000;
          // display: flex;
          // align-items: center;
          > p {
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            box-sizing: border-box;
          }
          > span {
            background: wheat;
            position: absolute;
            left: 0px;
            width: 20px;
            height: 26px;
            z-index: 0;
            opacity: 0.01;
          }
          &:hover {
            //  border: 1px solid rgb(141, 18, 18);
            > div {
              // opacity: 1;
              display: flex;
            }
          }
          &_sub {
            position: absolute;
            top: 23px;
            left: -20px;
            width: 60px;
            height: 80px;
            background: rgba(177, 177, 177, 0.8);
            border: 1px solid white;
            border-radius: 10%;
            font-size: 12px;
            font-weight: normal;
            // opacity: 0;
            display: flex;
            align-content: space-around;
            flex-wrap: wrap;
            display: none;
            p {
              width: 100%;
              height: fit-content;
              padding: 3px 0;
              &:hover {
                background: white;
              }
            }
          }
        }

        img {
          width: 100%;
          height: 230px;
          border-radius: 20px;
          object-fit: cover;
        }
        .name {
          margin: 10px 0 0 10px;
          color: black;
          font-size: 16px;
          font-weight: bold;
          @include clamp(1);
          @include no-wrap();
        }
        .playerTol {
          margin: 3px 0 0 0;
          color: #535353;
          font-size: 14px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          span:nth-child(1) {
            width: 8px;
            height: 8px;
            background: #27ae60;
            border-radius: 100%;
            margin-right: 3px;
          }
          span:nth-child(2) {
            width: 80%;
            @include clamp(1);
            @include no-wrap();
          }
        }
      }
    }
  }
}
</style>
