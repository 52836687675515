import Network from '@/api/network';
import mode from './modeType';

// export const userLogin = (data) => Network.post('app/building/user/login', data)//用户登陆
// export const userLogout = (data) => Network.post('app/building/user/logout', data)//用户登出
// export const isLogin = () => Network.get('app/building/user/isLogin')//用户是否登出

export const getAssetList = (data) => Network.get('user/asset/list', data); // 获取资产列表

export const getSpacesList = (data) => Network.get('user/spaces', data); // 获取用户创建的空间列表

export const getSearchSpaceList = (data) => Network.get('user/search', data); // 搜索用户创建的空间列表

export const getExploreSpacesList = (data) => Network.get('spaces', data); // 获取探索的空间列表

export const getSearchExploreSpaceList = (data) => Network.get('search', data); // 搜索探索空间列表

export const getTemplateList = (data) => Network.get('user/template', data); // 获取模版列表

export const addAsset = (data) => Network.post('user/asset', data); // 添加资产到素材库  包括音乐 图片 模型

export const createSpace = (data) => Network.post('user/createSpace', data); // 创建用户空间

export const updateAsset = (data) =>
  Network.put(`user/asset/${mode.assetID}`, data); // 更新资产详情

export const getAssetInfo = (data) =>
  Network.get(`user/asset/${mode.assetID}`, data); // 获取资产详情

export const updateSpaceName = (data) =>
  Network.put(`user/space/${mode.uuid}`, data); // 更新场景名字

export const delSpace = () => Network.del(`user/space/${mode.uuid}`); // 删除场景

export const deleteAsset = (data) => Network.del('user/assets', data); // 批量删除资产

export const getSpaceInfo = () =>
  Network.get(`backend/${mode.user}/instance/${mode.uuid}`); // 获取场景详情

export const getSpaceList = (data) =>
  Network.get(`backend/${mode.user}/instance`, data); // 获取场景列表

export const spaceSubmit = (data) => Network.put('user/submit', data); // 场景提交审核

export const spacePublish = (data) => Network.put('user/publish', data); // 场景发布

export const spaceRevert = (data) => Network.put('user/revert', data); // 场景发布取消

export const spaceApproval = (data) =>
  Network.put(`backend/${mode.user}/instance/${mode.uuid}`, data); // 场景审批-批准

export const getAgroaToken = () =>
  Network.get(`user/space/${mode.uuid}/agroaToken`); // 获取声网的信息参数

// export const getAgroaToken = () =>Network.get(`user/space/${  mode.uuid  }/agroaToken`); // 获取声网的信息参数
