import mode from '@/api/modeType';

export const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; // 含最大值，含最小值
};
export const setLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};
export const getLocalStorage = (key) => {
  return JSON.parse(window.localStorage.getItem(key));
};

export const isWeChat = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.match(/MicroMessenger/i) == 'micromessenger';
};
export const PhoneVerify = (id) => {
  const patrn = /^1[3456789]\d{9}$/;

  if (patrn.test(id) == false) {
    return false;
  }
  return true;
};

export const buttomIsClick = () => {
  return mode.buttomIsClick;
};

export const setButtomIsClick = (_boo) => {
  mode.buttomIsClick = _boo;
};

export const formDate = (data, format) => {
  // console.log("data,time");
  const time = {
    'M+': data.getMonth() + 1,
    'D+': data.getDate(),
    'H+': data.getHours(),
    'm+': data.getMinutes(),
    's+': data.getSeconds(),
  };

  if (/(y+)/i.test(format)) {
    format = format.replace(
      RegExp.$1,
      `${data.getFullYear()}`.substr(4 - RegExp.$1.length),
    );
  }

  for (const k in time) {
    if (new RegExp(`(${k})`).test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1
          ? time[k]
          : `00${time[k]}`.substr(`${time[k]}`.length),
      );
    }
  }
  return format;
};

export const formatSeconds = (value) => {
  let secondTime = parseInt(value); // 秒
  let minuteTime = 0; // 分
  let hourTime = 0; // 小时
  if (secondTime >= 60) {
    minuteTime = parseInt(secondTime / 60);
    secondTime = parseInt(secondTime % 60);
    if (minuteTime >= 60) {
      hourTime = parseInt(minuteTime / 60);
      minuteTime = parseInt(minuteTime % 60);
    }
  }
  // if (secondTime != 0) {
  let result = `${
    parseInt(secondTime) < 10 ? parseInt(secondTime) : parseInt(secondTime)
  }秒`;
  // }

  if (minuteTime != 0) {
    result = `${
      parseInt(minuteTime) < 10 ? parseInt(minuteTime) : parseInt(minuteTime)
    }分${result}`;
  }
  if (hourTime != 0) {
    result = `${
      parseInt(hourTime) < 10 ? parseInt(hourTime) : parseInt(hourTime)
    }时${result}`;
  }
  return result;
};
export const formatSeconds1 = (value) => {
  let secondTime = parseInt(value); // 秒
  let minuteTime = 0; // 分
  let hourTime = 0; // 小时
  if (secondTime >= 60) {
    minuteTime = parseInt(secondTime / 60);
    secondTime = parseInt(secondTime % 60);
    if (minuteTime >= 60) {
      hourTime = parseInt(minuteTime / 60);
      minuteTime = parseInt(minuteTime % 60);
    }
  }
  let result = `${
    parseInt(secondTime) < 10
      ? `0${parseInt(secondTime)}`
      : parseInt(secondTime)
  }`;

  // if (minuteTime > 0) {
  result = `${
    parseInt(minuteTime) < 10
      ? `0${parseInt(minuteTime)}`
      : parseInt(minuteTime)
  }:${result}`;
  // }
  // if (hourTime > 0) {
  result = `${
    parseInt(hourTime) < 10 ? `0${parseInt(hourTime)}` : parseInt(hourTime)
  }:${result}`;
  // }
  return result;
};

export const isIOS = () => {
  if (/android/i.test(navigator.userAgent)) {
    console.log('Android'); // Android
    return false;
  }
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    console.log('iOS'); // iOS
    return true;
  }
};

export const randomNum = (n, m) => {
  return Math.floor(Math.random() * (m - n + 1) + n);
};

export const isPC = () => {
  const userAgentInfo = navigator.userAgent;

  const Agents = [
    'Android',
    'iPhone',

    'SymbianOS',
    'Windows Phone',

    'iPad',
    'iPod',
  ];

  let flagPc = true;

  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flagPc = false;

      break;
    }
  }

  return flagPc;
};
